body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  color: #333;
  text-align: center;
  background-color: #fff;
}

section {
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

section:nth-child(odd) {
  background-color: #f7f7f7;
}

section:nth-child(even) {
  color: #fff;
  background-color: #FF69B4;
}

section:nth-child(odd) h2,
section:nth-child(odd) p {
  color: #333;
}

h1 {
  font-size: 3.5rem;
  margin: 0;
  padding: 0;
  color: #FF69B4;
  max-width: 90%;
}

h2 {
  font-size: 3rem;
  margin: 0;
  padding: 0;
  color: #fff;
  max-width: 90%;
}

p {
  font-size: 2rem;
  margin: 0;
  padding: 0;
  color: #fff;
  max-width: 80%;
  margin-top: 1rem;
}

small {
  font-style: italic;
  margin-top: 1rem;
  max-width: 80%;
}

.img {
  width: 100%;
  object-fit: cover;
  margin-bottom: -5px;
  max-width: 800px;
}

#countdown-section > p {
  margin-bottom: 3rem;
}
